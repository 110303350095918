import orderBy from 'lodash/orderBy';
import filter from 'lodash/filter';

export interface AnimalResult {
    id: string,
    name: string,
    avatarUrl: string,
    url: string,
    category: string
};

export default class AnimalResource {
    async search(terms : string) : Promise<AnimalResult[]> {
        const res = await fetch('/data/animals.json');

        const results : AnimalResult[] = await res.json();

        if (!terms || terms === '') return results;

        const regex = new RegExp(terms, 'gi');
        const filteredResults = orderBy(filter(results, (animal) => animal.name.match(regex)), 'name') as AnimalResult[];
        return filteredResults;
    }
}
