import React, { FC } from 'react';
import FilteredListLayout, { Title, Content, Filters, SubFilters, Results, SearchBox } from '@amzn/bui-react/lib/layouts/filtered-list-layout';
import Button from '@amzn/bui-react/lib/components/button';
import FormGroup from '@amzn/bui-react/lib/components/form-group';
import Checkbox from '@amzn/bui-react/lib/components/checkbox';
import RadioButton from '@amzn/bui-react/lib/components/radio-button';
import Toast from '@amzn/bui-react/lib/components/toast';
import AnimalList from './components/animal-list-results';

interface AppProps {}

const App : FC<AppProps> = () => {
  return (
    <FilteredListLayout>
    <Title>
        <h1>Office Supplies top selection</h1>
        <p>A list created based on your company profile and industry trends</p>
        <div className="b-text-right">
            <Button primary test-id="primary-button">Save to my lists (179)</Button>
        </div>
    </Title>
    <Content>
        <Filters>
            <SearchBox
                id="search-box"
                label="Refine by"
                placeholder="Find animals"
                test-id="search-box"
            />
            <SubFilters label="Filter" test-id="sub-filters">
                <FormGroup>
                    <label htmlFor="Result Type">Result Type</label>
                    <Checkbox label="Mammals" id="people-filter" />
                    <Checkbox label="Birds" id="groups-filter" />
                </FormGroup>
                <FormGroup>
                    <RadioButton
                        name="stacked-example"
                        checked="checked"
                        value="one"
                        label="Mammals Checkbox"
                    />
                    <RadioButton
                        name="stacked-example"
                        value="two"
                        label="Birds Checkbox"
                    />
                </FormGroup>
            </SubFilters>
        </Filters>
        <Results>
            <Toast role="alert" show type="info">
                Some helpful information
            </Toast>
            <AnimalList limit={10} />
        </Results>
    </Content>
</FilteredListLayout>
  )
};

export default App;