/* eslint-disable react/prop-types */
import React, { FC, useEffect, useState, Suspense } from 'react';
import take from 'lodash/take';
import List from '@amzn/bui-react/lib/components/list';
import LoadingOverlay from '@amzn/bui-react/lib/components/loading-overlay';

import AnimalResource from '../resources/animal-resource';

const AnimalListItem = React.lazy(() => import('./lazy-animal-list-item'));

interface AnimalListProps {
    limit: number
}

const AnimalList : FC<AnimalListProps> = ({ limit = 10, ...props }) => {
    const [loading, setLoading] = useState(false);
    const [results, setResults] = useState([]);

    const animalResource = new AnimalResource();

    useEffect(() => {
        const doAction = async () => {
            setLoading(true);
            try {
                const results = await animalResource.search('');
                setResults(take(results, limit));
            } catch(e) {
                console.error(e);
            } finally {
                setLoading(false);
            }
        }

        doAction();
    }, []);

    return (
        <List unstyled group {...props}>
            <LoadingOverlay loading={loading} />
            {results.map(animal => <Suspense key={animal.id} fallback={<div>Loading...</div>}><AnimalListItem {...animal} /></Suspense>)}
        </List>
    );
};

export default AnimalList;